import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import ContactSidebar from "../components/ContactSidebar";
import AdresseSidebar from "../components/AdresseSidebar";
import AvantApresBlock from "../components/AvantApresBlock";
import KeybodyBlock from "../components/KeybodyBlock";
import VideoBlock from "../components/VideoBlock";
import NewsBlock from "../components/NewsBlock";
import Carrousel from "../components/Carrousel";
import Layout from "../components/layout";
import Bio from "../components/Bio";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Social from "../components/Social";
import { Link } from "gatsby";
import { FaHome } from "react-icons/fa";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import _ from "lodash";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data

  const { frontmatter, html } = markdownRemark;

  // filter current page fron other page array
  const autresPages = _.remove(allMarkdownRemark.edges, function (arg) {
    return arg.node.frontmatter.path !== frontmatter.path;
  });

  const Wrapper = g.div({
    maxWidth: `1000px`,
    margin: `0 auto`,
  });

  const Title = g.h1({
    fontSize: `33px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `0 auto .3em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `42px`,
    "@media(max-width: 767px)": {
      fontSize: `24px`,
      lineHeight: `32px`,
      textAlign: `center`,
      margin: `5px auto 10px`,
    },
  });

  const Notes = g.div({
    fontSize: `12px`,
    marginTop: `4em`,
    lineHeight: `17px`,
    marginBottom: `2em`,
  });

  css.global(".gatsby-resp-image-figcaption", {
    textAlign: "center",
    fontStyle: `italic`,
    fontSize: `.85em`,
    lineHeight: `1.45em`,
    marginTop: `.5em`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
    "@media(max-width: 767px)": {
      marginBottom: `17px !important`,
    },
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>{frontmatter.metaTitle}</title>
          <meta name="description" content={frontmatter.metaDescription} />
          <html lang="fr" />
          {frontmatter.pathEn !== "/en/" && (
            <link
              rel="alternate"
              hreflang="en"
              href={`https://www.dr-kron.com${frontmatter.pathEn}`}
            />
          )}
          <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Avant-Après",
              "item": "https://www.dr-kron.com/avant-apres/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "${frontmatter.title}",
              "item": "https://www.dr-kron.com${frontmatter.path}"
            }]
        }
    `}</script>
        </Helmet>
        <Menu pathEn={frontmatter.pathEn} />

        <Wrapper>
          <Container
            css={{
              padding: `50px 15px 0 !important`,
              "@media(max-width: 767px)": {
                padding: `20px 15px 0 !important`,
              },
            }}
          >
            <Row>
              <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                <article>
                  {/* Breadcrumbs */}
                  {frontmatter.tags[0] === "avant-apres" && (
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link
                          to="/"
                          {...cssBreadcrumbLink}
                          title="Page d'accueil"
                        >
                          <FaHome
                            css={{
                              fontSize: `1.2em`,
                              paddingBottom: `.2em`,
                              transform: `translateY(5px)`,
                            }}
                          />
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to="/avant-apres/" {...cssBreadcrumbLink}>
                          Avant-Après
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>{frontmatter.title}</BreadcrumbItem>
                    </Breadcrumb>
                  )}
                  <Title>{frontmatter.title}</Title>

                  <div
                    className="page-content-avant-apres"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <Social path={frontmatter.path} title={frontmatter.title} />
                  <Notes>
                    <p>
                      «&nbsp;La description de la nature des actes et techniques
                      réalisés par le médecin peut donner lieu à l'affichage de
                      fiches d'information qui précéderont, accompagneront ou
                      suivront l'information délivrée par le médecin au cours de
                      la consultation. Elles peuvent être illustrées de schémas.
                      Mais la publication de photos, même rendues anonymes, ou
                      d'une iconographie «&nbsp;avant et après&nbsp;» pourrait
                      entraîner une confusion entre l'obligation d'information
                      et l'obligation de résultats. Le Conseil national
                      considère, en outre, que cette publication peut comporter
                      des dérives de type publicitaire pour un public non
                      averti. De même l'exposé de situation individuelle, sous
                      l'apparence de «&nbsp;cas clinique&nbsp;» est formellement
                      à proscrire.&nbsp;»
                    </p>

                    <p>
                      Source : Charte de conformité ordinale applicable aux
                      sites web des médecins du{" "}
                      <a
                        href="https://www.conseil-national.medecin.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Conseil National de l'Ordre des Médecins
                      </a>
                      &nbsp;(mai 2010)
                    </p>

                    <p>
                      Les photographies avant - après présentées par ce site
                      sont des illustrations des informations dispensées sur les
                      fiches consacrées aux interventions. En tant
                      qu'illustration, ces photos avant - après ont une valeur
                      informative et non publicitaire : elles mettent en
                      évidence le résultat pouvant être obtenu en fonction de
                      l'anatomie d'un patient ainsi que les éventuelles
                      cicatrices pouvant être induites par une intervention. Par
                      ailleurs, les photographies n'engagent pas le Docteur Kron
                      à fournir un résultat déterminé.
                    </p>

                    <p>
                      Le Docteur Kron rappelle que l'information délivrée aux
                      patients sur ce site ne se substitue en aucun cas à la
                      consultation médicale. Toute personne qui souhaite
                      bénéficier d'information complémentaire est invitée à
                      consulter un praticien qualifié en Chirurgie Plastique,
                      Esthétique et Reconstructrice.
                    </p>
                  </Notes>
                  <Bio />
                </article>
              </Col>
              <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                <aside>
                  <ContactSidebar about={frontmatter.about} />
                  <AdresseSidebar />
                  <AvantApresBlock />
                  <KeybodyBlock />
                  <VideoBlock />
                  <NewsBlock />
                </aside>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Carrousel other={autresPages} />
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AvantApresPostByPath($path: String!, $tag: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        pathEn
        title
        tags
        about
        metaTitle
        metaDescription
      }
    }
    allMarkdownRemark(
      limit: 28
      filter: { frontmatter: { tags: { eq: $tag } } }
      sort: { fields: frontmatter___shortTitle, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            shortTitle
            excerpt
            path
            image {
              childImageSharp {
                fluid(maxWidth: 367) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
